<template>
    <div v-if="showGoogleMaps">
        <div class="mapnote">
            <span vo-msg="google.map.note1">Google Maps wird genutzt, da Sie der Anzeige von Google Maps gem. unserer</span>
            <voffice-doc-box doc-id="privacy">
                <a class="footer-link"
                    href=""
                    vo-msg="google.map.note.datenschutz">Datenschutzerklärung</a>
            </voffice-doc-box> <span vo-msg="google.map.note2">zugestimmt haben.</span>
            <br><a href=""
                @click.prevent="preventGoogleMaps"
                vo-msg="google.map.note.prevent">Zustimmung widerrufen und Google Maps nicht verwenden.</a>
        </div>
        <iframe id="googleIframeContact"
            src="https://maps.google.com/maps?q=ok-Vermietung%20Kirchweg%206%2025938%20Wyk%20auf%20F%C3%B6hr&t=&z=13&ie=UTF8&iwloc=&output=embed"
            width="100%"
            height="300"
            frameborder="1"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            style="border:0;"
            allowfullscreen=""
            loading="lazy"></iframe>
    </div>
    <div v-else-if="!showGoogleMaps">
        <div class="mapconsent">
            <p>
                <span vo-msg="google.map.consent.note1">Bitte stimmen Sie der Anzeige von Google Maps zu. Erst dann kann die Karte geladen werden.</span>
                <br><span vo-msg="google.map.consent.note2">Weitere Details zum Aufruf von Google Maps finden Sie in unserer</span></p>
            <voffice-doc-box doc-id="privacy">
                <a class="footer-link mapconsent-button"
                    href=""
                    vo-msg="google.map.note.datenschutz">Datenschutzerklärung</a>
            </voffice-doc-box>
            <br><a href=""
                @click.prevent="allowGoogleMaps"
                class="mapconsent-button"
                vo-msg="google.map.allow.link">Jetzt Nutzung zustimmen!</a>

        </div>
    </div>
</template>

<script>
    export default {
    	voVueComponent: 'voffice-contact-map',
    	data() {
    		return {
    			showGoogleMaps: false,
    		};
    	},
    	mounted() {
    		this.canShowGoogleMaps();
    	},
    	methods: {
    		allowGoogleMaps: function() {
    			localStorage.setItem('allowGoogleMaps', true);
    			this.showGoogleMaps = true;
    			//location.reload();
    		},
    		preventGoogleMaps: function() {
    			localStorage.setItem('allowGoogleMaps', false);
    			this.showGoogleMaps = false;
    			//location.reload();
    		},
    		canShowGoogleMaps() {
    			const GoogleMaps = localStorage.getItem("allowGoogleMaps");
    			const el = document.getElementById("googleIframeContact");

    			if (GoogleMaps === "true") {
    				this.showGoogleMaps = true;
    			} else {
    				this.showGoogleMaps = false;
    			}
    		},
    	},
    };
</script>