export default {
	data: function () {
		return {
			selectedImageIndex: undefined,
			showGoogleMaps: false,
		};
	},
	beforeMount: function () {
		//console.warn("ha", window.location.search);
		var url = new URL(window.location.href);
		var params = {};
		for (let key of url.searchParams.keys()) {
			//console.log("p", key, url.searchParams.get(key));
			params[key] = url.searchParams.get(key);

		}
		this.searchParams = params;
	},
	computed: {

		mailto: function () {
			return `mailto:${this.emailAddress}`;
		},
		emailAddress: function () {
			return (VOFFICE.contactEmail || ['info', 'test.de']).join('@');
		}
	},
	methods: {
		allowGoogleMaps: function () {
			localStorage.setItem('allowGoogleMaps', true);
			location.reload();
		},
		preventGoogleMaps: function () {
			localStorage.setItem('allowGoogleMaps', false);
			location.reload();
		},
		canShowGoogleMaps: function () {
			var GoogleMaps = localStorage.getItem("allowGoogleMaps");
			var el = document.getElementById('googleIframeContact');

			if (GoogleMaps === 'true') {
				this.showGoogleMaps = true;
				if (this.$refs.googleIframeContact) {
					this.$refs.googleIframeContact.src = 'https://maps.google.com/maps?q=ok-Vermietung%20Kirchweg%206%2025938%20Wyk%20auf%20F%C3%B6hr&t=&z=13&ie=UTF8&iwloc=&output=embed';
				}
				/*if (el) {
					console.log('element gefunden');
					el.src = 'https://maps.google.com/maps?q=ok-Vermietung%20Kirchweg%206%2025938%20Wyk%20auf%20F%C3%B6hr&t=&z=13&ie=UTF8&iwloc=&output=embed';
				} else {
					console.log('element nicht gefunden');
				} */
			} else {
				this.showGoogleMaps = false;
			}
		}
	}

};