var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showGoogleMaps
    ? _c("div", [
        _c(
          "div",
          { staticClass: "mapnote" },
          [
            _c("span", { attrs: { "vo-msg": "google.map.note1" } }, [
              _vm._v(
                "Google Maps wird genutzt, da Sie der Anzeige von Google Maps gem. unserer"
              )
            ]),
            _vm._v(" "),
            _c("voffice-doc-box", { attrs: { "doc-id": "privacy" } }, [
              _c(
                "a",
                {
                  staticClass: "footer-link",
                  attrs: { href: "", "vo-msg": "google.map.note.datenschutz" }
                },
                [_vm._v("Datenschutzerklärung")]
              )
            ]),
            _vm._v(" "),
            _c("span", { attrs: { "vo-msg": "google.map.note2" } }, [
              _vm._v("zugestimmt haben.")
            ]),
            _vm._v(" "),
            _c("br"),
            _c(
              "a",
              {
                attrs: { href: "", "vo-msg": "google.map.note.prevent" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.preventGoogleMaps($event)
                  }
                }
              },
              [_vm._v("Zustimmung widerrufen und Google Maps nicht verwenden.")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("iframe", {
          staticStyle: { border: "0" },
          attrs: {
            id: "googleIframeContact",
            src:
              "https://maps.google.com/maps?q=ok-Vermietung%20Kirchweg%206%2025938%20Wyk%20auf%20F%C3%B6hr&t=&z=13&ie=UTF8&iwloc=&output=embed",
            width: "100%",
            height: "300",
            frameborder: "1",
            scrolling: "no",
            marginheight: "0",
            marginwidth: "0",
            allowfullscreen: "",
            loading: "lazy"
          }
        })
      ])
    : !_vm.showGoogleMaps
    ? _c("div", [
        _c(
          "div",
          { staticClass: "mapconsent" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("voffice-doc-box", { attrs: { "doc-id": "privacy" } }, [
              _c(
                "a",
                {
                  staticClass: "footer-link mapconsent-button",
                  attrs: { href: "", "vo-msg": "google.map.note.datenschutz" }
                },
                [_vm._v("Datenschutzerklärung")]
              )
            ]),
            _vm._v(" "),
            _c("br"),
            _c(
              "a",
              {
                staticClass: "mapconsent-button",
                attrs: { href: "", "vo-msg": "google.map.allow.link" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.allowGoogleMaps($event)
                  }
                }
              },
              [_vm._v("Jetzt Nutzung zustimmen!")]
            )
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { attrs: { "vo-msg": "google.map.consent.note1" } }, [
        _vm._v(
          "Bitte stimmen Sie der Anzeige von Google Maps zu. Erst dann kann die Karte geladen werden."
        )
      ]),
      _vm._v(" "),
      _c("br"),
      _c("span", { attrs: { "vo-msg": "google.map.consent.note2" } }, [
        _vm._v(
          "Weitere Details zum Aufruf von Google Maps finden Sie in unserer"
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }