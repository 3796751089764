import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';


import {
	doReq
}
from 'voUtils/RestEx.js';

import {
	findResultFields
}
from 'voUtils/BrowserEx.js';

export default {
	data: function () {
		return {
			units: []
		};
	},
	// props: {
	// 	filter: Object,
	// 	sorting: String,
	// 	max: String
	// },
	beforeMount: function () {
		var results = this.$el.getElementsByClassName('unit-list-section');
		if (results.length) {
			this.resultFields = findResultFields(results[0].innerHTML, this.$el);
		}

		var req = {
			fields: this.resultFields,
			filter: {
				unitgroupid: 22868
			},
			sorting: 'code'

		};

		doReq('searchUnits', req)
			.then((res) => {
				this.units = res.units;

			});

	},
	updated: function () {
		this.applySlider();
		$('[data-toggle="tooltip"]').tooltip();
	},
	methods: {
		getUnitSearchTarget: function (id) {
			if (window.screen.width < 768) {
				//probably phone?
				return '_self';
			} else {
				return 'unit_' + id;
			}
		},

		applySlider: function () {
			setTimeout(() => {
				$(".owl-carousel.latest-deals").owlCarousel({
					items: 3,
					loop: true,
					margin: 30,
					smartSpeed: 3000,
					autoplay: false,
					nav: true,
					responsive: {
						0: {
							items: 1,
							nav: true
						},
						600: {
							items: 2,
							margin: 15
						},
						1000: {
							items: 3,
							nav: true
						}
					}
				});
			}, 50);
		}
	}

};